import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Container from '../components/container'
// import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import Hero from '../components/hub/hero/hero'
import CardSlider from '../components/hub/cardSlider/cardSlider'
import SubscribeBar from '../components/subscribeBar/subscribeBar'
import BlockContent from '../components/block-content/v2'

import * as styles from '../components/globals-v2.module.css'
import Button from '../components/button/button'

export const query = graphql`
  query VideoTemplateQuery($id: String!) {
    video: sanityVideo(id: { eq: $id }) {
        _type
        tags {
          title
          slug {
            current
          }
        }
        title
        footnote
        slug {
          current
        }
        series {
          title
        }
        videoId
        featureImage {
          asset {
            _id
            url
          }
        }
        backgroundImage {
          asset {
              _id
              url
          }
        }
        splashScreen {
          asset {
            _id
            url
          }
        }
        _rawTranscript
        metaTitle
        metaDescription
        metaKeywords
        metaImageFB {
          asset {
              _id
              url
          }
        }
        metaImageTW {
          asset {
              _id
              url
          }
        }
    }

    episodes: allSanityVideo(limit: 10, sort: {order: DESC, fields: publishedAt}) {
      nodes {
          _type
          tags {
            title
            slug {
              current
            }
          }
          title
          slug {
            current
          }
          series {
            title
          }
          featureImage {
            asset {
              _id
              url
            }
          }
      }
    }
  }
`

const VideoTemplate = props => {
  const { data, errors } = props
  const [reveal, setReveal] = useState(false);
  const video = data && data.video
  // console.log(video);
  const episodes = data.episodes.nodes;
  
  return (
    <Layout hideContactBar={true} hideAnnouncements={true} logoOverrideUrl="/start/" darkMode={true} version="2">
      {/* {errors && <SEO title='GraphQL Error' />}
      {landing && <SEO title={landing.title || 'Untitled'} />}

      {errors && (
        <Container>
          <GraphQLErrorList errors={errors} />
        </Container>
      )} */}

      <SEO title={video.metaTitle || video.title} description={video.metaDescription} keywords={video.metaKeywords} imageFB={video.metaImageFB} imageTW={video.metaImageTW} />

      <Hero type="video" record={video} />

      <div className={styles.backgroundControlDark}>
            <Container size="large" class={styles.sectionPad}>
                <section className={`episodes`}>
                    <h6 style={{margin: "0 0 -60px"}}>EPISODES</h6>
                    <CardSlider type="simple" data={episodes} settings={{slidesToShow: 3}} alternate={true} />
                </section>
            </Container>
        </div>

        {video._rawTranscript && (
          <div className={`${styles.backgroundControl} ${styles.transcript} ${reveal && styles.reveal}`}>
              <Container size="large" class={styles.sectionPad}>
                  <div className={styles.split}>
                    <div>
                      <h6 style={{margin: "0", textTransform: "initial"}}>Video Transcript</h6>
                    </div>
                    <div>
                      <BlockContent blocks={video._rawTranscript} />
                    </div>
                  </div>
                  {!reveal && (
                    <Button level="secondary" onClick={() => setReveal(true)}>Load more</Button>
                  )}
              </Container>
          </div>
        )}

        <div className={styles.backgroundControlAlt}>
            <Container size="large">
                <SubscribeBar />
            </Container>
        </div>
    </Layout>
  )
}

export default VideoTemplate
